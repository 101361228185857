*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.hover{
  transition: all 0.40s;
}

.main-header {
  position: sticky;
  top: 0;
  z-index: 50; 
  background-color: white;
  transition: transform 0.3s ease; 
}

.header-link {
  position: relative;
  text-decoration: none;
  font-size: 17px;
}

.header-link::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  display: block;
  margin-top: 2px;
  right: 0;
  background: dodgerblue;
  transition: width 0.3s ease;
  -webkit-transition: width 0.3s ease;
}
.header-dropdown-link{
  position: relative;
  color: #000;
  text-decoration: none;
}

.header-dropdown-link::after{
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  display: block;
  margin-top: 30px;
  right: 0;
  background: dodgerblue;
  transition: width 0.3s ease;
  -webkit-transition: width 0.3s ease;
}

.header-dropdown-link:hover::after{
  width: 100%;
  left: 0;
  background: dodgerblue;
}


.header-link:hover::after {
  width: 100%;
  left: 0;
  background: dodgerblue;
}

.header-dropdown {
  color: #000;
  text-decoration: none;
}

.group:hover .header-dropdown {
  color: dodgerblue;
}

.group:hover .dropdown-menu {
  display: block;
}

.dropdown-menu {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.4s ease, transform 0.4s ease;
}

.group:hover .dropdown-menu {
  display: block;
  opacity: 1;
  transform: translateY(0);
  transition: all 1s;
}

.group .dropdown-menu {
  display: none;
}

.group:hover .dropdown-menu {
  display: block;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 12px; 
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #e5e7eb; 
  border-radius: 9999px; 
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #f2f7f6;
  border-radius: 9999px; 
  transition: background-color 0.3s ease; 
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #b1b2b2; 
}

.custom-scrollbar::-webkit-scrollbar-thumb:active {
  background-color: #9d9d9d; 
}